import { Button } from "@mui/material";
import { FC, MouseEvent } from "react";
import { IDraw } from "./DrawItem";
import { useDispatch, useSelector } from "../../hooks";
import { buyDrawTicket } from "../../redux/draws/buy-ticket.handler";
import { setBalanceError, setUserBalance } from "../../redux/user/user-info.slice";

interface IBuyDrawTicketBlockProps {
    draw: IDraw;  
}

export const BuyDrawTicketBlock: FC<IBuyDrawTicketBlockProps> = ({ draw }) => {
    const dispatch = useDispatch();
    const { balance } = useSelector(store => store.user.info);

    const handler = async (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        
        if (balance < draw.ticketCost) {
            dispatch(setBalanceError(`Не хватает РУБЛ для покупки пропуска. Требуется ${draw.ticketCost} РУБЛ.`))
            return;
        }
        
        const resultAction = await dispatch(buyDrawTicket(draw.drawId));
        if (buyDrawTicket.fulfilled.match(resultAction)) {
            const { balance } = resultAction.payload;
            if (balance !== undefined)
                dispatch(setUserBalance(balance));
        }
    }

    return (
        <div
            style={{ textAlign: 'center' }}
        >
            <Button
                sx={{
                    height: '30pt',
                    width: 'auto',
                    margin: '5pt',
                }}

                variant={ 'outlined' }
                onClick={ handler }
            >                
                { (`Купить пропуск`).toUpperCase() }
            </Button>
        </div>
    );
}