import { Dialog, DialogContent, useTheme } from '@mui/material';
import { FC, ReactNode, useEffect, useRef } from 'react';

interface ModalNotificationProps {
    open: boolean;
    onClose: () => void;
    children: ReactNode;
}

export const ModalNotification: FC<ModalNotificationProps> = ({ open, onClose, children }) => {
    const theme = useTheme();
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                onClose();
            }
        };
    
        document.addEventListener('click', handleClickOutside);
    
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [onClose]);

    return (
        <Dialog
            sx={{ padding: 0 }}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }
            }}
            ref={modalRef}
        >
            <DialogContent sx={{ padding: 0, border: `1px solid ${theme.palette.primary.main}`, borderRadius: '3pt' }}>
                { children }
            </DialogContent>
        </Dialog>
    );
};