import { ActionReducerMapBuilder, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { api } from "../../api/api";
import { IDrawsState } from ".";
import { DrawMemberStatus } from "../../pages/DrawPage/DrawItem";

export interface IConfirmWinResponseDto {
    drawId: number;
    memberStatus: DrawMemberStatus;
}

export const confirmDrawWin = createAsyncThunk<IConfirmWinResponseDto, number>(
    'confirmDrawWin',
    async (drawId: number) => await api.confirmDrawWin(drawId)
);

export const confirmDrawWinHandler = (builder: ActionReducerMapBuilder<IDrawsState>) => {    
    builder
        .addCase(confirmDrawWin.pending, (state: IDrawsState) => {
            state.loading = true;
            state.error = "";
        })
        .addCase(confirmDrawWin.fulfilled, (state: IDrawsState, action: PayloadAction<IConfirmWinResponseDto>) => {
            state.loading = false;
            const draw = state.items.find(draw => draw.drawId === action.payload.drawId);

            if (!draw) {
                state.error = 'Unable to confirm draw win';
                return;
            }

            draw.userStatus = action.payload.memberStatus;
        })
        .addCase(confirmDrawWin.rejected, (state: IDrawsState) => {
            state.loading = false;
            state.error = 'Unable to confirm draw win';
        });
};

export default confirmDrawWinHandler;