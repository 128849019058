import { Typography, Stack, useTheme } from "@mui/material";
import { FC } from "react";
import { BuyDrawTicketBlock } from "./BuyDrawTicketBlock";
import { DrawStatus } from "./DrawStatus";
import { truePlayersForm, truePrizesForm } from "../../utils/true.word.form";
import { ConfirmDrawWinBlock } from "./ConfirmDrawWinBlock";

export enum DrawMemberStatus {
    None = 0,
    Member = 1,
    Candidate = 2,
    Winner = 3,
    Disabled = 4,
}

export interface IDraw {
    drawId: number;
    ticketCost: number;
    membersCount: number;

    prizeName: string;
    prizeCount: number;

    userStatus: DrawMemberStatus;
}

export interface IDrawItemProps {
    draw: IDraw;
}

export const DrawItem: FC<IDrawItemProps> = ({ draw }) => {
    const theme = useTheme();    
    
    const getAction = () => {
        switch (draw.userStatus) {
            case DrawMemberStatus.None: return <BuyDrawTicketBlock draw={ draw } />;
            case DrawMemberStatus.Candidate: return <ConfirmDrawWinBlock draw={ draw } />;
            default: return <DrawStatus memberStatus={ draw.userStatus } />
        }
    }

    return (
        <Stack 
            direction="column"
            spacing={ '5pt' }
            sx={{
                border: '1px solid',
                borderRadius: '2pt',
                padding: '3pt',
                color: theme.palette.primary.main,
            }}
        >
            <Stack
                direction="row"
                spacing={0}
            >
                <Typography
                    sx={{
                        width: '100%',
                        fontSize: '10pt',
                    }}
                >
                    <div>{ draw.prizeName }</div>
                    <div>{ `${draw.prizeCount} ${truePrizesForm(draw.prizeCount)}` }</div>
                </Typography>            

                <Typography
                    sx={{
                        fontSize: '10pt',
                        paddingLeft: '3pt',
                    }}
                >
                    { `Участвует ${draw.membersCount} ${truePlayersForm(draw.membersCount)}` }
                </Typography>

                <Typography
                    sx={{
                        fontSize: '10pt',
                        paddingLeft: '3pt',
                    }}
                >
                    { `Цена ${draw.ticketCost} РУБЛ` }
                </Typography>
            </Stack>

            { getAction() }
            
        </Stack>
    );
}

// 'Вы уже участвуете в этом розыгрыше'