import { Button } from "@mui/material";
import { FC, MouseEvent } from "react";
import { IDraw } from "./DrawItem";
import { useDispatch } from "../../hooks";
import { confirmDrawWin } from "../../redux/draws/confirm-win.handler";

interface IBuyDrawTicketBlockProps {
    draw: IDraw;  
}

export const ConfirmDrawWinBlock: FC<IBuyDrawTicketBlockProps> = ({ draw }) => {
    const dispatch = useDispatch();

    const handler = async (_event: MouseEvent<HTMLButtonElement>) => {
        dispatch(confirmDrawWin(draw.drawId));
    }

    return (
        <div
            style={{ textAlign: 'center' }}
        >
            <Button
                sx={{
                    height: '30pt',
                    width: 'auto',
                    margin: '5pt',
                }}

                variant={ 'outlined' }
                onClick={ handler }
            >
                { (`Подтвердить выигрыш`).toUpperCase() }
            </Button>
        </div>
    );
}