import { FC } from "react";
import { DrawMemberStatus } from "./DrawItem";
import { Typography, useTheme } from "@mui/material";

interface IDrawStatusProps {
    memberStatus: DrawMemberStatus;
}

export const DrawStatus: FC<IDrawStatusProps> = ({ memberStatus }) => {
    const theme = useTheme();

    const getStatusDescription = () => {
        switch (memberStatus) {
            case DrawMemberStatus.Member: return `Вы купили билет и участвуете в розыгрыше!`;
            case DrawMemberStatus.Confirmed: return `Вы победили в розыгрыше! Наш бот отправит вам приз в ближайшее время.`;
            case DrawMemberStatus.Winner: return `Вы победили в розыгрыше! Ищите полученый приз в нашем боте.`;
        }
    }

    return (
        <Typography
            variant="h6"
            component="div"
            style={{
                border: '1px solid',
                borderRadius: '5pt',
                color: theme.palette.primary.main,
                fontWeight: 'bold',
                textAlign: 'center',
                padding: theme.spacing(1),
                margin: '5pt'
            }}
        >
            { getStatusDescription() }
        </Typography>
    );
}