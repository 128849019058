import { Alert, Typography, useTheme } from '@mui/material';
import { useDispatch, useSelector } from "../../hooks";
import { ModalNotification } from '../App/ModalNotification/ModalNotification';
import InfoIcon from '@mui/icons-material/Info';
import { setBalanceError } from '../../redux/user/user-info.slice';

export const BalanceDisplay = () => {
    const theme = useTheme();
    const { balance, balanceError } = useSelector(store => store.user.info);
    const dispatch = useDispatch();
    
    const handleClose = () => {
        dispatch(setBalanceError(''));
    }

    return (
        <>
            <Typography
                variant="h5"
                component="div"
                style={{
                    border: '1px solid',
                    borderRadius: '5pt',
                    color: theme.palette.primary.main,
                    fontWeight: 'bold',
                    textAlign: 'center',
                    padding: theme.spacing(1),
                    marginBottom: '5pt'
                }}
            >
                {balance.toFixed(0)} РУБЛ
            </Typography>

            { balanceError.length > 0 &&                
                <ModalNotification open={ true } onClose={ handleClose } >
                    <Alert
                        icon={
                            <InfoIcon
                                fontSize="inherit"
                                color="warning"
                                sx={{ paddingTop: '2pt' }}
                            />
                        }
                        severity="info"
                        sx={{ fontSize: '14pt' }}
                    >
                        { balanceError }
                    </Alert>
                </ModalNotification>
            }
        </>
    );
};