import { ActionReducerMapBuilder, createSlice, Draft } from "@reduxjs/toolkit";
import { IRequestState } from "../request.state.interface";
import getDrawsHandler from "./get-draws.handler";
import { IDraw } from "../../pages/DrawPage/DrawItem";
import buyDrawTicketHandler from "./buy-ticket.handler";
import confirmDrawWinHandler from "./confirm-win.handler";

export interface IDrawsState extends IRequestState {
    items: IDraw[];
};

const initialState: IDrawsState = {
    items: [],

    loading: false,
    error: "",
};

const drawsSlice = createSlice({
    name: 'draws',
    initialState,
    reducers: {
        drawsClearError: (state: Draft<IDrawsState>) => {
            state.error = "";
        },
    },
    extraReducers: (builder: ActionReducerMapBuilder<IDrawsState>) => {
        getDrawsHandler(builder);
        buyDrawTicketHandler(builder);
        confirmDrawWinHandler(builder);
    },
});

export const { drawsClearError } = drawsSlice.actions;
export default drawsSlice.reducer;